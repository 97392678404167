import { useContext } from "react";
import { Row, Col, Typography, Space } from "antd";
import Image from "next/image";

import Spinner from "components/Common/Spinner";
import { AuthContext } from "components/Common/AuthProvider";

const { Title } = Typography;

export default function IndexPage() {
  const session = useContext(AuthContext);

  if (!session) {
    return <Spinner />;
  }

  return (
    <div>
      <Row>
        <Col span={4} />
        <Col span={16} style={{ textAlign: "center", paddingTop: "5em" }}>
          <Space direction="vertical">
            <Image src="/images/doosan_logo_big.png" alt="Doosan Logo" width="500" height="234" />
            <Title id="title" level={2}>
              Welcome, {session.profile.name}!
            </Title>
            <Title level={3}>Please choose a customer</Title>
          </Space>
        </Col>
        <Col span={4} />
      </Row>
    </div>
  );
}
